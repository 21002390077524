<template>
  <b-form-group :label="input.label">

      <b-form-file
        :state="error ? false: true"
        accept=".json"
      />
      <b-form-invalid-feedback v-if="error">
        {{error}}
      </b-form-invalid-feedback>
  </b-form-group>
</template>
<script>
import { BFormFile, BFormGroup, BFormInvalidFeedback } from "bootstrap-vue";

export default {
  components: {
    BFormFile,
    BFormGroup,
    BFormInvalidFeedback
  },
  data(){
    return {
      error: null,
    }
  },
  props: ["input"],

};
</script>
