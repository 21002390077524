<template>
  <span>
    <h2 v-if="value['_label']">{{ value["_label"] }}</h2>
    <b-img fluid :src="value.value" alt="Coming soon page" />
  </span>
</template>

<script>
import { BImg } from "bootstrap-vue";
export default {
  components: { BImg },
  props: ["value"],
};
</script>
