<template>
  <b-form-group :label="input.label">
    <b-form-select
      v-model="selected"
      :options="this.renderOptions"
      size="lg"
      class="demo-inline-spacing"
    />
  </b-form-group>
</template>

<script>
import { BFormSelect, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    BFormSelect,
    BFormGroup,
  },
  props: ["input", "options", "value"],
  data: () => ({
    selected: null,
    renderOptions: [],
  }),
  mounted() {
    this.renderOptions = this.options.map((option) => ({
      value: option,
      text: option,
    }));
    this.selected = this.value ? this.value : "";
  },
  watch: {
    selected() {
      this.$emit("select:change", this.selected);
    },
  },
};
</script>
