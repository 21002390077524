<template>
  <div class="output-renderer">
    <b-row>
      <b-col lg="12">
        <b-col lg="12">
          <loading-data
            v-if="values && values.loading ? values.loading : false"
          />

          <contilt-title
            v-if="item.type == outputTypes.MAINTITLE"
            :item="item"
            :value="values.value"
          ></contilt-title>

          <contilt-sub-title
            v-if="item.type == outputTypes.SUBTITLE"
            :value="values.value"
          ></contilt-sub-title>

          <contilt-text
            v-if="item.type == outputTypes.TEXT && values.value"
            :item="item"
            :value="values.value"
          ></contilt-text>
          <contilt-imagelink
            v-if="item.type == outputTypes.IMAGELINK"
            :value="{ value: item.value }"
          ></contilt-imagelink>
          <contilt-imagebase
            v-if="item.type == outputTypes.IMAGECARDBASE64"
            :value="item.value"
          ></contilt-imagebase>

          <text-list
            v-if="item.type == outputTypes.TEXTLIST"
            :value="values.value"
          >
          </text-list>

          <contilt-imagecard
            v-if="
              item.type == outputTypes.IMAGELINKCARD
            "
            :info="{value: values.value}"
          >
          </contilt-imagecard>

          <contilt-for-output
            v-if="
              item.type == outputTypes.FOR_OUTPUT
            "
            :selectedOutput="selectedOutput"
            :value="values.value"
          >
          </contilt-for-output>

          <contilt-input
            v-if="
              item.type == outputTypes.INPUT && outputUpdatedData[item.name]
            "
            :value="values.value"
            :input="{ label: outputUpdatedData[item.name].label }"
          >
          </contilt-input>
        </b-col>
        <hr v-if="item.type == outputTypes.SPLITTER" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import contiltText from "../text/text.vue";
import contiltTitle from "../maintitle/maintitle.vue";
import contiltSubTitle from "../subtitle/subtitle.vue";
import contiltImagelink from "../imagelink/imagelink.vue";
import contiltImagebase from "../imagebase64/imagebase.vue";
import { outputTypes } from "../../../inputconstants";
import TextList from "../textlist/textlist.vue";
import LoadingData from "../loading/loading-data";
import ContiltInput from "../../../inputsFactory/inputelements/input/Contiltinput.vue";
import contiltImagecard from "../imagecard/imagecard.vue";
import ContiltForOutput from "../foroutput/ContiltForOutput.vue"
export default {
  components: {
    BRow,
    BCol,
    contiltText,
    contiltTitle,
    contiltImagelink,
    contiltImagebase,
    TextList,
    LoadingData,
    contiltSubTitle,
    contiltImagecard,
    ContiltInput,
    ContiltForOutput
  },
  props: ["values", "item", "selectedOutput"],
  computed: {
    outputTypes() {
      return outputTypes;
    },
  },
};
</script>
