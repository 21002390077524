<template>
  <div>
    <b-card>
      <b-card-title>{{ title }}</b-card-title>

      <b-form v-if="sampleinputs && sampleinputs.length > 0">
        <b-col cols="12" :key="index" v-for="(input, index) in sampleinputs">
          <contilt-input
            v-if="input.type == inputTypes.INPUT"
            :input="input"

            v-on:input:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-input>
          <contilt-image
            v-if="input.type == inputTypes.IMAGE"
            :input="input"
            v-on:image:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-image>
          <contilt-csv-file
            v-if="input.type == inputTypes.CSV_FILE"
            :input="input"
            v-on:file:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-csv-file>
          <contilt-json-file
            v-if="input.type == inputTypes.JSON_FILE"
            :input="input"
            v-on:file:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-json-file>
          <contilt-zip-file
            v-if="input.type == inputTypes.FOLDER_UPLOAD"
            :input="input"
            v-on:file:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-zip-file>
          <contilt-textarea
            v-if="input.type == inputTypes.TEXTAREA || input.type == inputTypes.STRING"
            :input="input"
            v-on:textarea:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-textarea>

          <contilt-checkbox
            v-if="input.type == inputTypes.CHECKBOX"
            :input="input"
            v-on:checkbox:change="onInputchanged(input, $event)"
          >
          </contilt-checkbox>

          <contilt-select
            v-if="input.type == inputTypes.SELECT"
            :input="input"
            label="key"
            :options="input.options"
            v-on:select:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-select>

          <contilt-multiselect
            v-if="input.type == inputTypes.MULTISELECT"
            :input="input"
            v-on:multiselect:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-multiselect>

          <contilt-radiolist
            v-if="input.type == inputTypes.RADIOLIST"
            :input="input"
            v-on:radiolist:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-radiolist>

          <contilt-checkboxlist
            v-if="input.type == inputTypes.CHECKBOXLIST"
            :input="input"
            v-on:checkboxlist:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-checkboxlist>

          <contilt-formtag
            v-if="input.type == inputTypes.FORMTAG"
            :input="input"
            v-on:formtag:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-formtag>

          <contilt-switch
            v-if="input.type == inputTypes.SWITCH"
            :input="input"
            v-on:switch:change="onInputchanged(input, $event)"
            :value="resultsData[input.name]"
          >
          </contilt-switch>
        </b-col>
      </b-form>
      <div style="text-align: center;" v-else>
        <b-spinner type="grow" label="Loading..." variant="primary" />
      </div>
    </b-card>
    <b-button v-if="generate"
      id="formButton"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      type="submit"
      variant="primary"
      class="mr-1"
      @click="$emit('on-generate', resultsData)"
      block
      :disabled="generationLoading || !isValidQuota"
    >
      <b-spinner v-if="generationLoading" small />
      {{ generateLabel || (isValidQuota ? "Generate" : "No Enough Credits") }}
    </b-button>
  </div>
</template>

<script>
import { inputTypes } from "../inputconstants";
import {
  BCol,
  BCard,
  BCardTitle,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import ContiltInput from "./inputelements/input/Contiltinput.vue";
import ContiltImage from "./inputelements/image/ContiltImage.vue";
import ContiltCsvFile from "./inputelements/file/ContiltCsvFile.vue";
import ContiltJsonFile from "./inputelements/file/ContiltJsonFile.vue";
import ContiltZipFile from "./inputelements/file/ContiltZipFile.vue";
import ContiltTextarea from "./inputelements/textarea/contilttextarea.vue";
import ContiltSelect from "./inputelements/select/select.vue";
import ContiltMultiselect from "./inputelements/multiselect/multiselect.vue";
import ContiltFormtag from "./inputelements/formtag/formtag.vue";
import contiltCheckbox from "./inputelements/checkbox/checkbox.vue";
import contiltRadiolist from "./inputelements/radoilist/radiolist.vue";
import contiltSwitch from "./inputelements/switch/contiltswitch.vue";
import contiltCheckboxlist from "./inputelements/checkboxlist/checkboxlist.vue";
import Ripple from "vue-ripple-directive";
import { mapState, mapActions } from 'vuex';

export default {
  directives: {
    Ripple,
  },
  components: {
    BCol,
    BForm,
    BCard,
    BButton,
    BSpinner,
    BCardTitle,
    ContiltInput,
    ContiltImage,
    ContiltTextarea,
    ContiltSelect,
    ContiltMultiselect,
    ContiltFormtag,
    contiltCheckbox,
    contiltRadiolist,
    contiltSwitch,
    contiltCheckboxlist,
    ContiltCsvFile,
    ContiltJsonFile,
    ContiltZipFile,
  },
  props: [
    "title",
    "generateLabel",
    "generationLoading",
    "generate",
    "sampleinputs",
    "userQuota"
  ],

  data() {
    return {
     
      inputTypes: inputTypes,
    };
  },
  updated() {
    this.$refs.formButton && this.$refs.formButton.focus();
  },
  methods: {
    onInputchanged(input, val){
      this.resultsData[input.name] = val
      this.$emit("on-input-changed")
    }
  },
  computed: {

    isValidQuota() {
      return this.userQuota > 0;
    },

    resultsData(){
      let result = {};
      let length = this.sampleinputs ? this.sampleinputs.length : 0;
      let i = 0;

      if (this.sampleinputs) {
        for (i; i < length; i++) {
          switch (this.sampleinputs[i].type) {
            case inputTypes.CHECKBOX:
            case inputTypes.SWITCH:
              result[this.sampleinputs[i].name] = this.sampleinputs[i].value
                ? this.sampleinputs[i].value
                : false;
              break;
            case inputTypes.CHECKBOXLIST:
            case inputTypes.FORMTAG:
            case inputTypes.MULTISELECT:
            case inputTypes.RADIOLIST:
              result[this.sampleinputs[i].name] = this.sampleinputs[i].value
                ? this.sampleinputs[i].value
                : [];
              break;
            default:
              result[this.sampleinputs[i].name] = this.sampleinputs[i].value
                ? this.sampleinputs[i].value
                : "";
          }
        }

        

        
      }
      return result;
    }
 
  },
};
</script>
