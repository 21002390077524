<template>
  <b-card-title> {{ value }}</b-card-title>
</template>

<script>
import { BCardTitle } from "bootstrap-vue";

export default {
  components: { BCardTitle },
  props: ["item", "value"],
};
</script>
