var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.outputsFromSer && Object.keys(this.outputsFromSer).length > 0)?_c('div',{class:{'contilt-output': !_vm.isContainFor }},[_vm._l((this.outputStructure),function(output,index){return _c('div',{key:index,class:("inner-output output-" + (output.nodeId) + " " + (output.nodeId == _vm.selectedOutput ? ' selected': ''))},[(
          output.type == _vm.outputTypes.MULTIFIELD &&
          _vm.outputUpdatedData[output.name])?_c('div',{staticStyle:{"position":"relative"}},[_c('contilt-multifield',{attrs:{"values":_vm.outputUpdatedData[output.name],"output":output}})],1):(output.type == _vm.outputTypes.FOR_OUTPUT)?_c('div',{staticClass:"contilt-output-for"},[_c('contilt-outputs-renderer',{attrs:{"selectedOutput":_vm.selectedOutput,"values":_vm.outputUpdatedData[output.name],"item":output}})],1):_c('b-card',[(
          _vm.outputUpdatedData[output.name] &&(
          _vm.outputUpdatedData[output.name]['_label'] ||
          _vm.outputUpdatedData[output.name]['label'])
        )?_c('contilt-title',{attrs:{"value":_vm.outputUpdatedData[output.name]['_label']
            ? _vm.outputUpdatedData[output.name]['_label']
            : _vm.outputUpdatedData[output.name]['label']}}):_vm._e(),_c('contilt-outputs-renderer',{attrs:{"selectedOutput":_vm.selectedOutput,"values":_vm.outputUpdatedData[output.name],"item":output}})],1)],1)}),_c('b-modal',{ref:"report-modal",attrs:{"no-close-on-backdrop":"","title":"Report","ok-title":"Report","centered":"","cancel-variant":"outline-secondary"},on:{"hidden":_vm.resetReportModal,"ok":_vm.reportOk}},[_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.reportSubmit($event)}}},[_c('b-form-group',{attrs:{"state":_vm.reportState,"label":"Comment"}},[_c('b-form-textarea',{attrs:{"state":_vm.reportState,"required":""},model:{value:(_vm.report.reportMessage),callback:function ($$v) {_vm.$set(_vm.report, "reportMessage", $$v)},expression:"report.reportMessage"}})],1),_c('b-form-group',{attrs:{"state":_vm.reportState,"label":"Output rResult"}},[_c('b-form-textarea',{attrs:{"rows":"10","state":_vm.reportState,"required":""},model:{value:(_vm.report.outputResult),callback:function ($$v) {_vm.$set(_vm.report, "outputResult", $$v)},expression:"report.outputResult"}})],1)],1)])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }