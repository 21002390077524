<template>
  <b-form-group :label="this.input.label">
    <b-form-checkbox-group
      v-model="selected"
      :options="renderOptions"
      name="flavour-1"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormCheckboxGroup } from "bootstrap-vue";

export default {
  components: {
    BFormCheckboxGroup,
    BFormGroup,
  },
  props: ["input", "value"],
  data() {
    return {
      selected: [],
      renderOptions: [],
    };
  },
  mounted() {
    this.renderOptions = this.input.options.map((option) => ({
      value: option,
      text: option,
    }));
    this.selected = this.value ? this.value : [];
  },
  watch: {
    selected() {
      this.$emit("checkboxlist:change", this.selected);
    },
  },
};
</script>
