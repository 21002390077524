<template>
    <b-img fluid :src="this.value" alt="Coming soon page" />
  </template>
  
  <script>
  import { BImg } from "bootstrap-vue";
  export default {
    components: { BImg },
    props: ["value"],  
  };
  </script>
  