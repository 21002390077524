<template>
  <div>
    <b-form-group :label="input.label">
      <b-form-checkbox v-if="input.configsMap && input.configsMap.removeBackground" v-model="isRemoveBackground">
        Remove background
      </b-form-checkbox>

      <b-form-file
        style="margin-top: 10px;"
        @change="onFileUploads"
        accept="image/jpeg, image/png, image/gif"
      />

      <b-input-group v-if="input.configsMap && input.configsMap.searchForImages"  style="margin-top: 10px;" class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          @input="search"
          placeholder="Search for image"
          v-model="query"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="XIcon"
            class="cursor-pointer"
            @click="query = ''"
          />
        </b-input-group-append>
      </b-input-group>
      <b-form-group v-if="input.configsMap && input.configsMap.generateImage">
             <label for="basic-password">Generate image</label>
            <b-input-group>
              <b-form-textarea v-model="generateImageText" />
              <b-input-group-append>
                <b-button @click="generateImage" variant="outline-primary">
                  GO
                </b-button>
              </b-input-group-append>
            </b-input-group>
      </b-form-group>
      <div
        v-if="searchLoading"
        style="margin-top: 10px"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner />
      </div>
      <div
        class="images-container-main"
        v-if="(!searchLoading && images && images.length > 0) && (query.length > 0 || generateImageText.length>0)"
      >
        <div class="images-container">
          <div
            class="image image-container"
            :key="index"
            v-for="(image, index) in images"
          >
            <div
              class="image-class"
            
              @click="setImage(image)"
              :style="'background-image: url(\'' + image.url + '\')'"
            ></div>
           
            <b-spinner v-if="image.loading" class="set-image-loading" variant="primary"></b-spinner>
          </div>
        </div>
      </div>

    </b-form-group>
  </div>
</template>
<script>
import {
  BFormFile,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  BButton,
  BFormTextarea 
} from "bootstrap-vue";
import axios from "@axios";
import _ from "lodash";

export default {
  components: {
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BButton,
    BFormTextarea
  },
  props: ["input"],
  data() {
    return {
      generateImageText: "",
      images: [],
      query: "",
      isRemoveBackground: false,
      searchLoading: false,
    };
  },
  methods: {
    generateImage(){
      if(this.generateImageText){
        this.searchLoading = true;
        axios.post(`images/generate`, {
          config: {prompt: this.generateImageText, n: 3}, context: {}
        }).then(res => {
          const images = res.data.map(x=> {
            return {
              loading: false,
              url: x
            }
          });
          this.searchLoading = false;
          this.allImages = images;
          this.images = images;
        })
      }
    },
    search: _.debounce(function (e) {
      const query = this.query;
      this.searchLoading = true;
      return axios
        .get(`images/search?query=${query}&index=cimagesindex&limit=20`)
        .then((response) => {
          console.log(response);
          const images = response.data;
          images.forEach(element => {
            element.loading = false;
            element.url = element.url + "&w=500"
          });
          this.allImages = images;
          this.images = images;
          this.searchLoading = false;
        })
        .catch((error) => {
          this.searchLoading = false;
          console.log(error);
          return null;
        });
    }, 500),

    async setImage(image) {
      image.loading = true;
      if (this.isRemoveBackground) {
          const img = await this.removeBackground(image.url);
          image.loading = false;
         this.$emit("image:change", img);
      }else {
        this.$emit("image:change", image.url);
      }
      image.loading = false;
     
    },
    inputImageRenderer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            resolve(reader.result);
          },
          false
        );
        if (file) {
          reader.readAsDataURL(file);
        } else {
          resolve(null);
        }
      });
    },
    removeBackground(base64) {
      return new Promise((resolve, reject) => {
        axios
          .post("/images/removeBG", { image: base64 })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
   
  },
};
</script>

<style scoped>
.images-container {
  position: absolute;
  padding-top: 20px;
  overflow-y: scroll;
  max-height: 300px;
  width: 914px;
  z-index: 100;
  background: #f9f9f9;
  border-radius: 5px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.images-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.images-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.image-container .image-class {
  width: 284px;
  height: 260px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}
.image-class.loading {
      opacity: 0.3;
}
.image-container {
  margin: 10px;
  display: inline-block;
  position: relative;
}
.images-container-main {
  position: relative;
}
.image-container .image-class {
  width: 284px;
  height: 260px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}
.set-image-loading {
  position: absolute;
  right: 46%;
  top: 46%;
}
</style>