<template>
  <div>
    <b-card-text
      class="mt-1 mb-1"
      :key="index"
      v-for="(data, index) of valuesList"
    >
      {{ data }} <br />
    </b-card-text>
  </div>
</template>

<script>
import { BCardText } from "bootstrap-vue";

export default {
  components: { BCardText },
  props: ["item", "value"],
  computed: {
    valuesList() {
      const newData = this.value.split("\n");
      return newData;
    },
  },
};
</script>
