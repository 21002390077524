<template>
  <b-card-text class="mt-1 mb-1" v-html="currentValue"> </b-card-text>
</template>

<script>
import { BCardText } from "bootstrap-vue";

export default {
  components: { BCardText },
  props: ["item", "value"],
  computed: {
    currentValue() {
      return this.value.replace(/\n/g, "<br>")
    }
  }
};
</script>
