<template>
  <div class="demo-inline-spacing">
    <b-form-group :label="input.label">
      <b-form-radio-group
        v-model="selected"
        :options="this.renderOptions"
        value-field="value"
        text-field="text"
      >
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    BFormRadioGroup,
    BFormGroup,
  },
  props: ["input", "value"],
  data() {
    return {
      selected: "",
      renderOptions: [],
    };
  },
  mounted() {
    this.renderOptions = this.input.options.map((option) => ({
      value: option,
      text: option,
    }));
    this.selected = this.value ? this.value : "";
  },
  watch: {
    selected() {
      this.$emit("radiolist:change", this.selected);
    },
  },
};
</script>
