<template>
  <b-form-group :label="input.label">
    <v-select
      v-model="selected"
      label="title"
      :options="this.renderOptions"
      multiple
      :selectable="() => selected.length < maxSelection"
    >
    </v-select>
  </b-form-group>
</template>
<script>
import vSelect from "vue-select";
import { BFormGroup } from "bootstrap-vue";

export default {
  components: {
    vSelect,
    BFormGroup,
  },
  props: ["input", "value"],
  data() {
    return {
      selected: [],
      renderOptions: [],
      maxSelection: 0,
    };
  },
  mounted() {
    this.selected = this.value
      ? this.value
      : [{ title: this.input.options[0] }];
    this.maxSelection = this.input.maxSelections || this.input.options.length;
    this.renderOptions = this.input.options.map((option) => ({
      title: option,
    }));
  },
  watch: {
    selected() {
      const dataToSend = this.selected.map((item) => item.title);
      this.$emit("multiselect:change", dataToSend);
    },
  },
};
</script>
