<template>
  <div>
    <b-card v-if="showMultifield">
      <contilt-title :value="values['_label']" v-if="values['_label']">
      </contilt-title>

      <contilt-outputs-renderer
        :values="values[item.name]"
        :key="index"
        v-for="(item, index) in items"
        :item="item"
      ></contilt-outputs-renderer>
    </b-card>
    <div v-else></div>
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import contiltTitle from "../maintitle/maintitle.vue";
import ContiltOutputsRenderer from "../putputsRenderer/outputsRenderer.vue";

export default {
  components: {
    BCard,
    ContiltOutputsRenderer,
    contiltTitle,
  },
  props: ["values", "output"],
  computed: {
    items() {
      return this.output.items;
    },
    showMultifield() {
      let show = false;
      if (this.items.length) {
        const length = this.items.length;
        let i = 0;
        let item;
        for (i; i < length; i++) {
          item = this.items[i];
          if ((this.values[item.name] && this.values[item.name].value && !this.values[item.name].loading) || (this.values[item.name] && this.values[item.name].loading == true)) {
            show = true;
            break;
          }
        }
      }

      return show;
    },
  },
};
</script>
