<template>
  <b-form-group :label="input.label">

      <b-form-file
        accept=".csv"
      />
  </b-form-group>
</template>
<script>
import { BFormFile, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    BFormFile,
    BFormGroup,
  },
  props: ["input"],

};
</script>
