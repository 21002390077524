<template>
  <b-form-group :label="input.label">
    <b-form-textarea
      v-model="textareaValue"
      :placeholder="this.input.placeholder"
      :rows="input.numberOfLines || 6"
      :state="
        (textareaValue && textareaValue.length) <= (this.input.maxChars || 2000)
      "
    />
  </b-form-group>
</template>
<script>
import { BFormTextarea, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    BFormTextarea,
    BFormGroup,
  },
  props: ["input", "value"],

  computed: {
    textareaValue:{
      set(newVal) {
        this.$emit("textarea:change", newVal);
      },
      get(){
        return (this.value ? this.value : this.input.value) || ""
      }
    }
  }
 
};
</script>
