<template>
  <div :class="{'contilt-output': !isContainFor }" v-if="this.outputsFromSer && Object.keys(this.outputsFromSer).length > 0">
    <div :class="`inner-output output-${output.nodeId} ${output.nodeId == selectedOutput ? ' selected': ''}`" :key="index" v-for="(output, index) in this.outputStructure">
      <div style="position: relative;" v-if="
            output.type == outputTypes.MULTIFIELD &&
            outputUpdatedData[output.name]">
        
        <contilt-multifield

          :values="outputUpdatedData[output.name]"
          :output="output"
        ></contilt-multifield>
      </div>
      <div class="contilt-output-for" v-else-if="output.type == outputTypes.FOR_OUTPUT">
        <contilt-outputs-renderer
          :selectedOutput="selectedOutput"
          :values="outputUpdatedData[output.name]"
          :item="output"
        ></contilt-outputs-renderer>
      </div>
      <b-card v-else>
        <contilt-title
          :value="
            outputUpdatedData[output.name]['_label']
              ? outputUpdatedData[output.name]['_label']
              : outputUpdatedData[output.name]['label']
          "
          v-if="
            outputUpdatedData[output.name] &&(
            outputUpdatedData[output.name]['_label'] ||
            outputUpdatedData[output.name]['label'])
          "
        >
        </contilt-title>

        <contilt-outputs-renderer
          :selectedOutput="selectedOutput"
          :values="outputUpdatedData[output.name]"
          :item="output"
        ></contilt-outputs-renderer>
      </b-card>
    </div>
    <b-modal
      no-close-on-backdrop
      ref="report-modal"
      title="Report"
      ok-title="Report"
      centered
      cancel-variant="outline-secondary"
      @hidden="resetReportModal"
      @ok="reportOk"
    >
      <form ref="form" @submit.stop.prevent="reportSubmit">


        <b-form-group :state="reportState" label="Comment">
          <b-form-textarea
            v-model="report.reportMessage"
            :state="reportState"
            required
          />
        </b-form-group>
        <b-form-group :state="reportState" label="Output rResult">
          <b-form-textarea
            rows="10"
            v-model="report.outputResult"
            :state="reportState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { BCol, BCard, BButton, BSpinner, BFormGroup, BFormTextarea } from "bootstrap-vue";
import { outputTypes } from "../inputconstants";
import contiltMultifield from "./outputelements/multifield/multifield.vue";
import ContiltOutputsRenderer from "./outputelements/putputsRenderer/outputsRenderer.vue";
import ContiltTitle from "./outputelements/maintitle/maintitle.vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios"
export default {
  name: "ContiltOutput",
  directives: {
    Ripple,
  },
  components: {
    contiltMultifield,
    ContiltOutputsRenderer,
    BCol,
    BCard,
    ContiltTitle,
    BButton,
    BSpinner,
    BFormGroup, BFormTextarea
  },
  props: ["outputStructure", "outputsFromSer", "templateId", "inputsResultsData", "taskId", "templateCallId", "selectedOutput"],
  data() {
    return {
      outputTypes: outputTypes,
      outputUpdatedData: null,
      regenerateNodes : {},
      report: {
        reportMessage: "",
        outputResult: "",
        outputId: null
      },
      reportState: null
    };
  },

  
  created() {
    
    this.outputUpdatedData = this.getOutputUpdatedData();
    this.regenerateNodes = this.getRegenerateNodes();
    
  },
  computed: {
    isContainFor(){
      if(this.outputStructure){
        return this.outputStructure.find(x=>x.type == "forOutput")
      }
      return false
    },
    sessionId(){
      return this.$route.query.sessionId;
    }
  },
  methods: {
    showReport(output){
      debugger;
      let text = "";
      if(output.type == "multifield" && output.items){
        if(this.outputsFromSer[output.name]) {
            output.items.forEach(x => {
              if(this.outputsFromSer[output.name][x.name]){
                text += this.outputsFromSer[output.name][x.name].value;
              }
            })
        }
       
        
      }else if(this.outputsFromSer[output.name] && this.outputsFromSer[output.name].value)  {
        text += this.outputsFromSer[output.name].value;
      }
      this.report.outputId = output.nodeId
      this.report.outputResult = text
      this.$refs['report-modal'].show()
    },
    checkReportFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.reportState = valid;
      return valid;
    },
    resetReportModal() {
      this.report = {
        reportMessage: "",
        outputResult: "",
        outputId: null
      };
      this.reportState = null;
    },
    reportOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.reportSubmit();
      this.resetReportModal();
    },
    reportSubmit() {
      debugger;
      // Exit when the form isn't valid
      if (!this.checkReportFormValidity()) {
        return;
      }
      const data = JSON.parse(JSON.stringify(this.report));
      data.template = this.templateId;
      data.session = this.sessionId;
      data.status = "OPEN"
      axios
      .post("/reports", data)
      .then(() => {
      })
      .catch((error) => console.log(error));


      this.$nextTick(() => {
        this.$refs["report-modal"].toggle("#toggle-btn");
      });
    },

    getRegenerateNodes(){
      let res = {}
      this.outputStructure.forEach(x => {
        res[x.nodeId] = false
      })
      return res;
    },

   
    getOutputUpdatedData() {
      let value = {};

      const outputlength = this.outputStructure.length;
      for (let i = 0; i < outputlength; i++) {
        if (this.outputStructure[i].type == outputTypes.SPLITTER) {
          continue;
        }

        if (this.outputStructure[i].type == outputTypes.MULTIFIELD) {
          let convertedData = {};

          for (const element of this.outputStructure[i].items) {
            if (element.type == outputTypes.SPLITTER) {
              continue;
            }
            if (!this.outputsFromSer[this.outputStructure[i].name]) {
              continue;
            }
            convertedData[element.name] = {};
            convertedData[element.name].value =
              this.outputsFromSer[this.outputStructure[i].name][
                element.name
              ].value;
            convertedData[element.name].loading =
              this.outputsFromSer[this.outputStructure[i].name][element.name]
                .loading || false;
          }
          value[this.outputStructure[i].name] = convertedData;
        } else if (this.outputStructure[i].type) {
          value[this.outputStructure[i].name] = {
            value: "value" in this.outputsFromSer[this.outputStructure[i].name] ? this.outputsFromSer[this.outputStructure[i].name].value : this.outputsFromSer[this.outputStructure[i].name],
            loading:
              this.outputsFromSer[this.outputStructure[i].name].loading ||
              false,
          };
        }

        if ("_label" in this.outputStructure[i]) {
          value[this.outputStructure[i].name]["_label"] =
            this.outputsFromSer[this.outputStructure[i].name] &&
            this.outputsFromSer[this.outputStructure[i].name]["_label"]
              ? this.outputsFromSer[this.outputStructure[i].name]["_label"]
              : this.outputStructure[i]["_label"];
        } else if ("label" in this.outputStructure[i] && this.outputStructure[i]["label"] && this.outputStructure[i].name) {
          value[this.outputStructure[i].name]["label"] =
            this.outputsFromSer[this.outputStructure[i].name] &&
            this.outputsFromSer[this.outputStructure[i].name]["label"]
              ? this.outputsFromSer[this.outputStructure[i].name]["label"]
              : this.outputStructure[i]["label"];
        }
      }
      return value;
    },
  },
};
</script>

<style>
  .contilt-session {
    z-index: 10000 !important;
    position: absolute !important;
    right: 10px !important;
    top: 10px !important;
  }

  .contilt-output-for .regenerate {
    display: none;
  }
  .inner-output.selected .card{
    background: #dadddf;
  }
</style>