<template>
  <div  class="main-templates" v-if="currentTemplate">
    <b-row>
      <b-col :lg="leftSide">
        <contilt-inputs
          :title="currentTemplate.title"
          :generateLabel="currentTemplate.generateLabel"
          :generationLoading="generationLoading"
      
        
          ref="contilt-inputs"
          :sampleinputs="sampleinputs"
        ></contilt-inputs>
      </b-col>



      <b-col :lg="rightSide">
        <div v-if="!pageOutputData">
          <b-overlay :show="generationLoading" rounded="sm">
            <div class="misc-inner p-2 p-sm-3">
              <div class="w-100 text-center">
                <h2 class="mb-1">Once done the result will appear here</h2>
                <p class="mb-3">
                  Contilt makes content creation fun. One click and your result
                  will be ready shortly!
                </p>

                <b-img fluid :src="imgUrl" alt="Coming soon page" />
              </div>
            </div>
          </b-overlay>
        </div>

        <div v-else>
         
          <ContiltOutput
            :selectedOutput="selectedOutput"
            :templateId="currentTemplate.id"
            :inputsResultsData="inputsResultsData"
            :generationLoading="generationLoading"
            @change-side-size="changeSlides"
            :taskId="taskCallLogId"
            :templateCallId="templateCallLogId"
            :key="generateUUid"
            :outputStructure="outputStructure"
            :outputsFromSer="pageOutputData"
          />

          <!-- <add-review></add-review> -->
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "@axios";
import ContiltOutput from "./outputsfactory/outputsfactory.vue";
import contiltInputs from "./inputsFactory/inputsFactory.vue";
import { v4 as uuidv4 } from "uuid";
import { BRow, BCol, BImg, BOverlay, BButton } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import Ripple from "vue-ripple-directive";

export default {
  props: ["selectedOutput"],
  components: {
    BRow,
    BCol,
    BImg,
    BOverlay,
    ContiltOutput,
    contiltInputs,
    BButton
  },
    directives: {
    Ripple,
  },
  setup() {

    const { userQuota } = useAppConfig();
    return { userQuota };
  },
  methods: {

    changeSlides(left, right){
      if(left){
        this.leftSide = left;
      }
      if(right){
        this.rightSide = right;
      }
    },

    
    getTemplatePromise() {
      const promise = new Promise((resolve, reject) => {
        return axios
          .get(`/templates/${this.$route.params.id}`)
          .then((response) => {
            if (response.data) {
              resolve(response);
            }
          })
          .catch((error) => {
            this.generationLoading = false;
            reject(error);
          });
      });
      return promise;
    },
    getSessionDataPromise() {
      const promise = new Promise((resolve, reject) => {
        return axios
          .get(`/templatescalllog/${this.$route.query.sessionId}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return promise;
    },
  },
  watch: {
    currentTemplate(old, newVal){
      setTimeout(() => {
        this.inputsResultsData = this.$refs["contilt-inputs"].resultsData
      }, 100);
      
    }
  },
  data() {
    return {
      inputsResultsData: null,
      taskCallLogId: null,
      templateCallLogId: null,
      leftSide: 6,
      rightSide: 6,
      downImg: require("@/assets/images/pages/coming-soon.svg"),
      generationLoading: false,
      information: null,
      pageOutputData: null,
      currentTemplate: null,
   
      generateUUid: uuidv4(),
    };
  },
  computed: {
    outputStructure() {
      return this.currentTemplate.outputs;
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/coming-soon-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },

 

    sampleinputs() {
      return this.currentTemplate.inputs;
    },

  },

  created() {
    let _this =this;
    
      const selectedTemplatePromise = this.getTemplatePromise();
      let promisesArray = [selectedTemplatePromise];

      if (this.$route.query.sessionId) {
        const selectedSessionPromise = this.getSessionDataPromise();

        promisesArray.push(selectedSessionPromise);
      }

      Promise.all(promisesArray).then((values) => {
        const selected = values[0].data;

        if (values[0]) {
          // selected template data here
          this.currentTemplate = values[0].data;
          this.currentTemplate = selected;
        }

        if (values[1]) {
          //session data here
          // this.pageOutputData = this.$route.params.sessionData.outputs;
          let sessionData = values[1].data.sessionData;
          _this.templateCallLogId = values[1].data.id;
          _this.taskCallLogId = values[1].data.task;
          if (sessionData && sessionData.inputs && sessionData.outputs) {
            const inputsUpdatedValues = [];
            let helpInput = {};
            selected.inputs.map((input) => {
              helpInput = { ...input };
              helpInput.value = sessionData.inputs[input.name] || input.value;
              inputsUpdatedValues.push(helpInput);
            });
            selected.inputs = [...inputsUpdatedValues];
          }
          this.pageOutputData = sessionData.outputs;
        }
      });
    
  },

};
</script>
