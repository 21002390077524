<template>
  <span v-if="info">
    <h6 v-if="info._label" class="my-2 text-muted">
      {{ info._label }}
    </h6>
    <b-card>
      <!-- <b-img v-if="img" fluid :src="img" /> -->
      <b-img v-if="info.value && typeof info.value == 'string'" fluid :src="info.value" />
      <div v-else-if="info.value && Array.isArray(info.value)">
        <b-img class="multiimage" :key="index" v-for="(img, index) in info.value" fluid :src="img" />
      </div>
    </b-card>
  </span>
</template>

<script>
import { BImg, BCard } from "bootstrap-vue";
import axios from '@axios';

export default {
  components: { BImg, BCard },
  props: ["info"],
  // data(){
  //   return {
  //     img: null
  //   }
  // },
  // created(){
  //   this.getImage(this.info.value);
  // },
  // methods: {
  //   async getImage(value) {
  //     if(Array.isArray(value)){
  //        this.img = value[0]
  //     }
  //     else if(value.startsWith("http")){
       
  //       const res = await axios.get(value);
  //       this.img = res.data;
  //     }
  //     else {
  //       this.img = value;
  //     }
  //   }
  // },
  // watch: {
  //   info: function(newVal) {
  //     this.getImage(newVal.value)
  //   },
  // },
};
</script>
<style scoped>
  .multiimage {
    margin-bottom: 20px;
  }
</style>