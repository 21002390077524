<template>
  <b-form-group :label="this.input.label">
    <b-form-tags
      v-model="selected"
      input-id="tags-separators"
      separator=" ,;"
      :placeholder="this.input.placeholder"
      no-add-on-enter
      class="mb-2"
    />
  </b-form-group>
</template>

<script>
import { BFormTags, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    BFormTags,
    BFormGroup,
  },
  props: ["input", "value"],
  data: () => ({
    selected: [],
  }),
  watch: {
    selected() {
      this.$emit("formtag:change", this.selected);
    },
  },
};
</script>
