<template>
    <div>
            <ContiltOutput :key="index" v-for="(item, index) in value" 
            :selectedOutput="selectedOutput"
            :outputStructure="item['_outputstructure']" 
            :outputsFromSer="item" />
           

    </div>
</template>
  
  <script>
  export default {
    components: { 
        ContiltOutput: () => import('../../outputsfactory.vue')
     },
    props: ["value", "selectedOutput"],
   
  };
  </script>
  