<template>
    <div v-if="report">
        <b-card>
        <b-row>
            <b-col md="6">
                <b-form-group>
                    <label>Status</label>
                    <v-select v-model="report.status" :reduce="option => option.id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value" :options="reportStatuses" />
                </b-form-group>
                <b-form-group>
                    <label for="textarea-default">Contilt comment</label>
                    <b-form-textarea
                    id="textarea-default"
                    placeholder="Textarea"
                    v-model="report.contiltMessage"
                    rows="3"
                    />
                </b-form-group>

             </b-col>
             <b-col md="6">
                <b-form-group>
                    <label for="textarea-default">User report message</label>
                    <b-form-textarea
                    id="textarea-default"
                    placeholder="Textarea"
                    v-model="report.reportMessage"
                    rows="3"
                    />
                </b-form-group>
                <b-form-group>
                    <label for="textarea-default">Fixed version</label>
                    <b-form-textarea
                    id="textarea-default"
                    placeholder="Textarea"
                    v-model="report.outputResult"
                    rows="5"
                    />
                </b-form-group>
             </b-col>
        
             
        </b-row>
        <b-row>
        <b-col cols="6">
            <b-button
            @click="saveReport"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          style=" width: 150px;"
          variant="primary"
          :disabled="saveLoading"
        >
        <b-spinner v-if="saveLoading" small />
          Save
        </b-button>
        </b-col>
        </b-row>
        </b-card>
        <TemplateForm :selectedOutput="report.outputId" />
    </div>
</template>

<script>
import TemplateForm from "../../../template/template-form/TemplateForm.vue";
import axios from "@axios";
import vSelect from 'vue-select';
import Ripple from "vue-ripple-directive";

import {
  BFormTextarea, BRow, BCol, BCard, BButton, BSpinner, BFormGroup
} from 'bootstrap-vue'
export default {
    data(){
        return {
            report: null,
            saveLoading: false,
            reportStatuses: [{
                id: "OPEN",
                value: "Open"
            },
            {
                id: "IN_PROGRESS",
                value: "In Progress"
            },
            {
                id: "CLOSED",
                value: "Closed"
            }
            ]
        }
    },
      directives: {
    Ripple,
  },
    components: {
        TemplateForm, BFormTextarea, BRow, BCol, BCard, BButton, BSpinner, BFormGroup, vSelect
    },
    computed: {
        reportId() {
            return this.$route.query.reportId;
        },
    },
    methods: {
        saveReport(){
            this.saveLoading = true;
            axios.patch(`reports/${this.reportId}`, this.report).then(() => {
                this.saveLoading = false
            }).catch(err => {
                this.saveLoading = false;
            })
        }
    },
    created(){
        this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
        axios.get(`reports/${this.reportId}`).then(res => {
            this.report = res.data;
        })
    }
}
</script>