<template>
  <div>
    <b-form-checkbox switch inline v-model="selected">
      {{ this.input.label }}
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    BFormCheckbox,
  },
  props: ["input", "value"],
  data: () => ({
    selected: "",
  }),
  mounted() {
    this.selected = this.value ? this.value : false;
  },
  watch: {
    selected() {
      this.$emit("switch:change", this.selected);
    },
  },
};
</script>
