<template>
  <b-form-checkbox v-model="selected" :value="input.name">
    {{ input.label }}
  </b-form-checkbox>

</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    BFormCheckbox,
  },
  props: ["input", "value"],
  data() {
    return {
      selected: this.value ?? [],
    };
  },
  watch: {
    selected() {
      this.$emit("checkbox:change", this.selected === this.input.name);
    },
  },
};
</script>
