<template>
  <b-card-text> {{ value }}</b-card-text>
</template>

<script>
import { BCardText } from "bootstrap-vue";

export default {
  components: { BCardText },
  props: ["value"],
};
</script>
